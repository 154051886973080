import { green, orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export default createTheme({
    palette: {
        primary: {
            light: green[300],
            main: "#00A071",
            dark: "#196458",
            contrastText: "#fff",
        },
        secondary: {
            light: orange[300],
            main: "#1A867E",
            dark: orange[700],
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "'Inter', sans-serif;",
    },
});
