import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "assets/style";
import { AuthProvider } from "contexts/auth";
import "cropperjs/dist/cropper.css";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";

const ProtectedRoutes = lazy(() => import("../routes"));
const Login = lazy(() => import("./Login"));

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AuthProvider>
                        <Suspense fallback={<></>}>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="*" element={<ProtectedRoutes />} />
                            </Routes>
                        </Suspense>
                    </AuthProvider>
                </LocalizationProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}
