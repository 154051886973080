// API routes
export const BACKENDBASEV1 =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8001/v1/"
        : "https://hq-api.worklife.so/v1/";
export const API_ROUTE_AUTH = `${BACKENDBASEV1}auth/`;
export const API_ROUTE_USER_LOGIN = `${API_ROUTE_AUTH}login/`;
export const API_ROUTE_USER_LOGOUT = `${API_ROUTE_AUTH}logout/`;
export const API_ROUTE_USER = `${BACKENDBASEV1}users/`;
export const API_ROUTE_USER_ME = `${API_ROUTE_USER}me/`;
export const API_ROUTE_ALL_WALLPAPERS = `${BACKENDBASEV1}wallpapers/`;
export const API_ROUTE_WEEKLY_WALLPAPERS = `${API_ROUTE_ALL_WALLPAPERS}?filter=weekly`;

// Frontend routes
export const APP_ROUTE_INNITIAL = "/";
export const APP_ROUTE_LOGIN = "/login";
export const APP_ROUTE_PREVIEW = "/preview";
export const APP_CREATE_SCHEDULE = "/create-schedule";
export const APP_ROUTE_ALL_SCHEDULE = "/all-schedule";
