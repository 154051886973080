import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {
    API_ROUTE_USER_LOGIN,
    API_ROUTE_USER_LOGOUT,
    API_ROUTE_USER_ME,
} from "constants/Routes";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        verifyToken(localStorage.getItem("token"));

        return () => {
            axios.defaults.headers.common["Authorization"] = "";
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyToken = (paramToken) => {
        if (!paramToken) {
            resetAuth();
            return;
        }

        axios.defaults.headers.common["Authorization"] = `Token ${paramToken}`;
        axios
            .get(API_ROUTE_USER_ME)
            .then((response) => {
                if (response.status === 200) {
                    setUser(response.data);
                    setToken(paramToken);
                    setLoading(false);
                } else {
                    resetAuth();
                }
            })
            .catch((err) => {
                resetAuth();
            });
    };

    const resetAuth = () => {
        localStorage.clear();
        setToken(null);
        setLoading(false);
        axios.defaults.headers.common["Authorization"] = "";
        navigate("/login");
    };

    const handleLogout = () => {
        axios.post(API_ROUTE_USER_LOGOUT, {}).then((response) => {
            if (response.status === 200) {
                resetAuth();
            }
        });
    };

    const handleLogin = (username, password) => {
        axios
            .post(API_ROUTE_USER_LOGIN, { username, password })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token);
                    verifyToken(response.data.token);
                }
            })
            .catch((err) => resetAuth());
    };

    if (loading)
        return (
            <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
            </Box>
        );
    return (
        <AuthContext.Provider
            value={{ token, user, loading, handleLogout, handleLogin }}
        >
            {children}
        </AuthContext.Provider>
    );
};
